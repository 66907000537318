<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col md="12">
                    <b-card :header="$t('yoksis_permissions')" class="soft">
                        <ValidationProvider name="start_date" rules="required" v-slot="{errors}">
                            <b-form-group :label="$t('start_date')">
                                <select-date v-model="formData.start_date"
                                             :validationError="errors[0]"
                                />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="end_date" rules="required" v-slot="{errors}">
                            <b-form-group :label="$t('end_date')">
                                <select-date v-model="formData.end_date"
                                             :validationError="errors[0]"
                                />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="decision_date" rules="required" v-slot="{errors}">
                            <b-form-group :label="$t('decision_date')">
                                <select-date v-model="formData.decision_date"
                                             :validationError="errors[0]"
                                />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="semester_count" rules="required" v-slot="{errors}">
                            <b-form-group :label="$t('semester_count')">
                                <multi-selectbox v-model="formData.semester_count"
                                                 :multiple="false"
                                                 :options="semesterOptions"
                                                 :validationError="errors[0]"
                                />
                            </b-form-group>
                        </ValidationProvider>
                    </b-card>
                </b-col>
            </b-row>
        </ValidationObserver>
        <div class="d-flex justify-content-center mt-2">
            <b-button variant="primary"
                      @click="createForm"
                      :disabled="formLoading">
                <b-spinner label="Spinning" class="mr-3" small v-show="formLoading"></b-spinner>
                {{ $t('save') }}
            </b-button>
        </div>
    </div>
</template>

<script>
    // Component
    import SelectDate from '@/components/interactive-fields/SelectDate'
    import MultiSelectbox from '@/components/interactive-fields/MultiSelectbox'

    // Services
    import YoksisService from "@/services/YoksisService"

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import moment from 'moment'

    export default {
        components: {
            SelectDate,
            MultiSelectbox,

            ValidationObserver,
            ValidationProvider
        },
        props: {
            studentProgramId: {
                type: Number
            }
        },
        data() {
            return {
                formLoading: false,
                formData: {},
                semesterOptions: [
                    {value: 1, text: 1},
                    {value: 2, text: 2}
                ]
            }
        },
        async created() {

        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    YoksisService.addPermit(this.formData, this.studentProgramId).then(response => {
                        console.log(response)
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('createFormSuccess');
                    }).catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    }).finally(() => {
                        this.formLoading = false
                    })
                }
            }
        }
    }
</script>

